<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex justify-content-between align-items-center">
              <div class="todo-date d-flex mr-3">
                <i :class="`ri-briefcase-2-line text-info mr-2`"></i>
                <span class="h5">{{ $t("sidebar.corporations") }}</span>
              </div>
              <b-button
                variant="primary"
                @click="$refs.modalCorporation.show()"
                >{{ $t("app.new") }}</b-button
              >
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-overlay :show="corporationsLoading" rounded="sm">
      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-col sm="12" md="1" class="pr-1 mb-2">
              <b-form-select
                :options="['5', '10', '20', '50', '100']"
                v-model="filter.per_page"
                size="sm"
                @input="getData()"
              ></b-form-select>
            </b-col>
            <b-col lg="3" md="6" sm="12" class="ml-auto mb-2">
              <b-form-group>
                <b-form-input
                  class="border"
                  style="height: 35px"
                  id="filter-input"
                  v-model="filter.search"
                  type="search"
                  :placeholder="$t('app.search')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="table-responsive">
            <b-table
              class="table mb-0 table-borderless"
              :items="corporations.data"
              :fields="columns"
            >
              <template v-slot:cell(avatar)="{ item }">
                <b-avatar
                  :src="item.avatar"
                  size="3rem"
                  rounded
                  class="bg-transparent"
                ></b-avatar>
              </template>
              <template v-slot:cell(created_at)="data">
                {{ moment(data.item.created_at).format("DD-MM-YYYY HH:mm") }}
                hrs.
              </template>
              <template v-slot:cell(action)="data">
                <b-button
                  variant=" iq-bg-warning rounded"
                  class="mr-1"
                  size="sm"
                  @click="$refs.modalCorporation.show(data.item)"
                  ><i class="ri-ball-pen-fill ml-1"></i
                ></b-button>
                <b-button
                  variant=" iq-bg-primary rounded"
                  class="mx-1"
                  @click="
                    $router.push({
                      name: 'admin.show-corporation',
                      params: {
                        id: data.item.id,
                      },
                    })
                  "
                  size="sm"
                  ><i class="ri-eye-fill ml-1"></i
                ></b-button>
                <b-button
                  @click="deleteItem(data.item.id)"
                  variant=" iq-bg-danger rounded"
                  size="sm"
                  ><i class="ri-delete-bin-line ml-1"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              class="mt-3 float-right"
              v-model="filter.page"
              :total-rows="corporations.total"
              :per-page="corporations.per_page"
              first-number
              pills
              size="sm"
              @change="getData"
            ></b-pagination>
            <p class="mt-3">
              {{ $t("app.registers") }} {{ corporations.to }}
              {{ $t("app.of") }} {{ corporations.total }}. Total
              {{ corporations.total }}
            </p>
          </div>
        </template>
      </iq-card>
    </b-overlay>

    <!-- Modal Form -->
    <modal-form-corporation ref="modalCorporation" @refresh="getData()" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalFormCorporation from "@/views/Corporations/Components/CreateOrUpdateCorporation.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
export default {
  name: "Corporations",
  components: {
    modalFormCorporation,
  },
  async mounted() {
    core.index();
    this.debouncedGetCorporations = _.debounce(this.getData, 500);
    await this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetCorporations();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchCorporations: "fetchCorporations",
      deleteCorporation: "deleteCorporation",
    }),
    updateItem(data) {
      this.$refs.modalCorporation.show(data);
    },
    async getData(page = 1) {
      this.filter.page = page;
      await this.fetchCorporations(this.filter);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteCorporation(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      filter: {
        paginate: true,
        page: 1,
        per_page: 10,
        search: null,
      },
      totalRows: 1,
      moment,
    };
  },
  computed: {
    ...mapGetters({
      corporations: "corporations",
      corporationsLoading: "corporationsLoading",
    }),
    columns() {
      return [
        { label: "", key: "image", class: "text-center" },
        {
          label: this.$t("corporations.name"),
          key: "name",
          class: "text-center",
        },
        {
          label: this.$t("corporations.business-name"),
          key: "business_name",
          class: "text-center",
        },
        {
          label: this.$t("corporations.rut"),
          key: "rut",
          class: "text-center",
        },
        {
          label: this.$t("corporations.created_at"),
          key: "created_at",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
<style>
.content-width {
  max-width: 460px !important;
}
</style>
